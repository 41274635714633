<template>
  <b-modal
    id="course-modal"
    ref="course"
    title="Tạo khoá học mới"
    @show="openStaffPopup"
    @hidden="resetModal"
    @ok="submit"
    style="min-width: 800px"
  >
    <b-container fluid class="p-0" v-if="rendered">
      <form ref="form-group" @submit="submit">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <basic-input
                  label="Tên khoá học"
                  placeholder="Nhập tên khoá học"
                  name="courseName"
                  :value.sync="filter.name"
                  :required="true"
                ></basic-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <basic-text-area
                  label="Mục tiêu khoá học "
                  placeholder="Nhập miêu tả cho mục tiêu khoá học "
                  name="description"
                  :value.sync="filter.description"
                ></basic-text-area>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="label-required"
                  >Cài đặt hiển thị khoá học trên App & theo gói dịch vụ
                </label>
                <b-col class="pl-0">
                  <basic-check-box
                    class="pb-1"
                    :options="[
                      { text: 'Hiển thị khoá học trên App', value: false },
                    ]"
                    :value.sync="filter.isActive"
                  ></basic-check-box>
                  <basic-check-box
                    class="pb-1"
                    :options="[
                      {
                        text: 'Chỉ hiển thị khoá học cho thành viên Cơ Bản',
                        value: false,
                      },
                    ]"
                    :value.sync="filter.isActive"
                  ></basic-check-box>
                  <basic-check-box
                    class="pb-1"
                    :options="[
                      {
                        text: 'Chỉ hiển thị khoá học cho thành viên Bạc',
                        value: false,
                      },
                    ]"
                    :value.sync="filter.isActive"
                  ></basic-check-box>
                  <basic-check-box
                    class="pb-1"
                    :options="[
                      {
                        text: 'Chỉ hiển thị khoá học cho thành viên Vàng',
                        value: false,
                      },
                    ]"
                    :value.sync="filter.isActive"
                  ></basic-check-box>
                </b-col>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row>
              <b-col>
                <b-row>
                  <b-col class="py-0">
                    <label for="sendDate" class="mb-0"
                      >Thơì gian hiệu lực</label
                    >
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <basic-date-picker
                      placeholder="Từ ngày"
                      name="startDateTimeToSend"
                    />
                  </b-col>
                  <b-col>
                    <basic-date-picker
                      placeholder="Đến ngày"
                      name="endDateTimeToSend"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="picture-input-container">
                  <picture-input
                    ref="pictureInput"
                    width="228"
                    height="128"
                    margin="16"
                    accept="image/jpeg,image/png"
                    size="16"
                    buttonClass="btn btn-success"
                    :crop="false"
                    :removable="true"
                    :customStrings="customStrings"
                  >
                  </picture-input>
                  <div class="mt-3 text-center">
                    <span>Hình ảnh khoá học, tỷ lệ 16:9</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button class="btn btn-success ml-3" type="submit" @click="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import PictureInput from 'vue-picture-input';
export default {
  name: 'CourseModal',
  components: {
    PictureInput,
  },
  data() {
    return {
      form: {},
      rendered: false,
      validationState: {},
      error: {},
      filter: {},
      groupFoods: [
        { name: 'Banh canh', id: 1 },
        { name: 'Hu tiu', id: 2 },
        { name: 'Com', id: 3 },
        { name: 'Banh tran tron', id: 4 },
      ],
    };
  },
  computed: {
    customStrings: {
      get() {
        return {
          drag: `<div style="
            height: 28px;
            width: 28px;
            background-color: #fff;
            box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
            margin: auto;"
            class="d-flex align-items-center justify-content-center rounded-circle">
            <span>
            <img src="/media/svg/icons/Neolex/Basic/edit-2.svg" alt="Edit Icon" class="svg-icon" width="14px" height="14px" />
            </span>
            </div>`,
          change: 'Đổi hình ảnh',
          remove: 'Xóa hình ảnh',
        };
      },
    },
  },
  methods: {
    openStaffPopup() {
      this.rendered = true;
      setTimeout(() => {
        this.$refs.pictureInput.onResize();
      }, 500);
    },
    submit() {},
    resetModal() {},
  },
};
</script>

<style lang="scss">
#course-modal {
  .modal-dialog {
    max-width: 80%;
  }
}
.label-required:after {
  content: '*';
  color: red;
}
</style>
<style lang="scss" scoped>
.picture-input-container {
  .picture-input {
    .preview-container {
      background: #ffffff;
      border: 3px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      border-radius: 6px;

      .picture-preview {
        background-color: #f3f6f9 !important;
      }

      .picture-inner {
        border: none;
      }
    }
  }
}
</style>
